export default defineNuxtRouteMiddleware(() => {
  const router = useRouter();
  const authStore = useUserStore();

  if (!authStore.authorized) {
    if (process.server) return navigateTo('/auth');
    else return router.push('/auth');
  }

  return;
});
